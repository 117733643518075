const userForm = () => {
  if (!document.querySelector("#user-form")) return;

  const role = document.querySelector("#user_role");
  const companies = document.querySelector("#companies");
  const workingArea = document.querySelector("#consultant-working-area");
  const cvWrap = document.querySelector("#cv-wrap");

  // on page only for an admin
  const partnerToggle =
    document.querySelector("#partner-toggle") || document.createElement("div");
  const unlockReport =
    document.querySelector("#unlock-report-toggle") ||
    document.createElement("div");

  role.addEventListener("change", (e) => {
    companies.style.display = "none";
    workingArea.style.display = "none";
    cvWrap.style.display = "none";
    partnerToggle.style.display = "none";
    unlockReport.style.display = "none";

    switch (e.target.value) {
      case "admin":
      case "editor":
        cvWrap.style.display = "block";
        partnerToggle.style.display = "block";
        if (e.target.value == "editor") unlockReport.style.display = "block";
        break;
      case "consultant":
        workingArea.style.display = "block";
        cvWrap.style.display = "block";
        break;
      default:
        companies.style.display = "block";
    }
  });
  role.dispatchEvent(new Event("change"));
};

export default userForm;
