const clickTrigger = () => {
  document.querySelectorAll(".click-trigger").forEach(function(trigger) {
    trigger.addEventListener("click", function(e) {
      e.preventDefault();

      const target = document.querySelector(trigger.dataset.target);
      if (target) target.click();
    });
  });
};

export default clickTrigger;
