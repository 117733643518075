const collapsible = () => {
  document.querySelectorAll(".collapsible").forEach(collapsible => {
    collapsible
      .querySelector(".collapsible-toggle")
      .addEventListener("click", e => {
        e.preventDefault();
        collapsible.classList.toggle("active");
      });
  });
};

export default collapsible;
