const jumpToTop = () => {
  const trigger = document.getElementById("scroll-down-trigger");
  if (!trigger) {
    return;
  }

  trigger.addEventListener("click", function(e) {
    e.preventDefault();

    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth"
    });
  });
};

export default jumpToTop;
