// autosave form
const initAutoSave = () => {
  let autoSubmitTimeoutID;

  const delayedSubmit = (form, miliseconds) => () => {
    clearTimeout(autoSubmitTimeoutID);
    autoSubmitTimeoutID = setTimeout(
      () => Rails.fire(form, "submit"),
      miliseconds
    );
  };

  document.querySelectorAll("form.autosave").forEach(form => {
    // instant submit
    form
      .querySelectorAll(
        "select, input[type=checkbox], input[type=radio], input[type=date]"
      )
      .forEach(input =>
        input.addEventListener("change", e => Rails.fire(form, "submit"))
      );

    // slow submit
    form
      .querySelectorAll("textarea, input[type=text], input[type=number]")
      .forEach(input =>
        ["keyup", "change"].forEach(event =>
          input.addEventListener(event, delayedSubmit(form, 1000))
        )
      );

    // fast submit
    form
      .querySelectorAll("input[type=search]")
      .forEach(input =>
        ["keyup", "search"].forEach(event =>
          input.addEventListener(event, delayedSubmit(form, 250))
        )
      );
  });
};

export default initAutoSave;
