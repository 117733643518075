const navigation = () => {
  const toggle = document.querySelector("#hamburger");
  if (!toggle) return;
  const navigation = document.querySelector("#navigation");
  const subLinks = navigation.querySelectorAll(".has-children > a");

  const active = () => document.body.classList.contains("show-navigation");
  const toggleActive = () => {
    document.body.classList.toggle("show-navigation");
    subLinks.forEach((link) => link.classList.remove("show"));
    // active()
    //   ? document.addEventListener("click", outsideClickListener)
    //   : removeOutsideClickListener();
  };
  const toggleCookie = () => {
    const value = active() ? "true" : "false";
    const maxAge = 7 * 24 * 3600; // 7 days
    document.cookie = `navigationActive=${value}; max-age=${maxAge}; path=/; samesite=lax;`;
  };

  // const removeOutsideClickListener = () =>
  //   document.removeEventListener("click", outsideClickListener);

  // const outsideClickListener = event => {
  //   if (!navigation.contains(event.target)) {
  //     toggleActive();
  //     subLinks.forEach(link => link.classList.remove("show"));
  //     removeOutsideClickListener();
  //   }
  // };

  toggle.addEventListener("click", (e) => {
    e.preventDefault();
    toggleActive();
    toggleCookie();
  });

  subLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      if (link.closest(".menu").classList.contains("secondary")) {
        active()
          ? link.classList.toggle("show")
          : link.closest("li").querySelector("ul.sub-menu a").click();
      } else {
        if (!active()) toggleActive();
        link.classList.toggle("show");
      }
    });
  });

  // document.addEventListener("turbolinks:before-visit", () =>
  //   removeOutsideClickListener()
  // );
};

export default navigation;
