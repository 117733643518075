// var list = document.querySelector('#test-list')

// [...list.children]
//   .sort((a,b)=>a.innerText>b.innerText?1:-1)
//   .forEach(node=>list.appendChild(node))

const cv = () => {
  const cvLines = document.querySelectorAll("form .cv-lines");
  if (!cvLines) return;

  cvLines.forEach(group => {
    const template = group.querySelector(".cv-line-template");
    const paramName = template.dataset.param;
    const linesList = group.querySelector(".cv-lines-list");
    const creator = group.querySelector(".new-cv-line");

    const autoSave = () => {
      const form = linesList.closest("form");
      if (form.classList.contains("autosave")) Rails.fire(form, "submit");
    };

    const addNewLine = () => {
      const copyAttributes = [
        "text",
        "start-year",
        "end-year",
        "type",
        "hidden"
      ];
      const resetAttributes = copyAttributes.slice(0, 3);

      if (
        [
          ...creator.querySelectorAll(
            resetAttributes
              .map(attribute => `.cv-line-${attribute}-input`)
              .join(", ")
          )
        ].every(input => input.value.trim() == "")
      )
        return;

      // validation
      const startYear = parseInt(
        creator.querySelector(".cv-line-start-year-input").value
      );
      const endYear = parseInt(
        creator.querySelector(".cv-line-end-year-input").value
      );
      if (endYear && startYear > endYear) {
        alert("End year cannot be lower than start year.");
        return;
      }

      // clone
      const newLine = template.cloneNode(true);
      newLine.classList.remove("cv-line-template");
      newLine.classList.add("cv-line");
      newLine.querySelectorAll("input").forEach(input => {
        input.name = input.name.replace("cv-line-template", paramName);
      });

      // set attribtues
      copyAttributes.forEach(attribute => {
        const selector = `.cv-line-${attribute}-input`;
        const input = creator.querySelector(selector);
        newLine.querySelector(selector).value = input.value;
        if (resetAttributes.includes(attribute)) {
          input.value = "";
        }
      });

      // set focus to first input
      creator.querySelector("input").focus();

      // append
      linesList.appendChild(newLine);

      // sort
      [...linesList.children]
        .sort((a, b) => {
          const startSelector = ".cv-line-start-year-input";
          const endSelector = ".cv-line-end-year-input";
          const aStart = parseInt(a.querySelector(startSelector).value) || 0;
          const aEnd = parseInt(a.querySelector(endSelector).value) || 0;
          const bStart = parseInt(b.querySelector(startSelector).value) || 0;
          const bEnd = parseInt(b.querySelector(endSelector).value) || 0;

          return aStart - bStart || aEnd - bEnd;
        })
        .forEach(node => linesList.appendChild(node));

      // trigger autosave
      autoSave();
    };

    // trigger addNewLine
    creator.querySelector("button").addEventListener("click", addNewLine);
    creator.querySelectorAll("input").forEach(input =>
      input.addEventListener("keydown", event => {
        if (event.key === "Enter") {
          event.preventDefault();
          addNewLine();
        }
      })
    );

    // remove line
    group.addEventListener("click", e => {
      const el = e.target;
      if (!el.classList.contains("delete-link")) return;
      e.preventDefault();
      const line = el.closest(".cv-line");
      line.parentNode.removeChild(line);
      autoSave();
    });

    // hide line
    group.addEventListener("click", e => {
      const el = e.target;
      if (!el.classList.contains("hide-link")) return;
      e.preventDefault();
      const line = el.closest(".cv-line");
      const hiddenInput = line.querySelector(".cv-line-hidden-input");
      const hidden = hiddenInput.value === "true";
      line.classList.toggle("cv-line-hidden");
      el.classList.toggle("hidden");
      hiddenInput.value = !hidden;
      autoSave();
    });
  });
};

export default cv;
