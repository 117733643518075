// redactor.min must come first!
import { fire } from "@rails/ujs";
import "./redactor/redactor.min";

import "./redactor/limiter.min";
import "./redactor/table.min";
import "./redactor/variable.min";



window.REDACTOR_EDITOR_VARIABLES = [
  "[vedlikehold_kostnads_tabell]",
  "[vedlikehold_kostnads_tabell_utten_area]",
  "[vedlikehold_kostnads_tabell_utten_mva]",
  "[vedlikehold_kostnads_tabell_utten_area_mva]",
  "[vedlikeholds_karakter]",

  "[cost_overview_table]",
  "[cost_overview_charts]",

  "[kostnadsfordeling_diagram]",
  "[kostnadsfordeling_tabell]",

  "[vedlikehold_kostnads_diagram]",
  "[vedlikehold_kostnadsfordeling_diagram]",

  "[prioritet_fargekoder]",
  "[prioritet_fargekoder_bygningssjekk]",
  "[dokumentasjons_karakter]",

  "[prosjektinformasjon_prosjektnavn]",
  "[prosjektinformasjon_prosjektnummer]",
  "[prosjektinformasjon_type]",
  "[prosjektinformasjon_adresse]",

  "[konsulenter_prosjektansvarlig]",
  "[konsulenter_utforende_konsulenter]",
  "[konsulenter_dokumentasjonskontroll]",
  "[konsulenter_ansvarlig_partner_KS]",

  "[eiendom_matrikkel]",
  "[eiendom_matrikkel_list]",
  "[matrikkel_gnr]",
  "[matrikkel_bnr]",
  "[matrikkel_komune]",
  "[matrikkel_skrafoto]",
  "[matrikkel_matrikkelkart]",
  "[matrikkel_snr]",

  "[miljo_forurenset_grunn]",
  "[miljo_radon_aktsomhet]",

  "[eiendom_hjemmelshaver]",
  "[eiendom_tomteareal]",
  "[eiendom_byggear]",

  "[oppdragsgiver_firmanavn]",
  "[oppdragsgiver_navn]",
  "[rapportkopi]",
  // "[reklamasjon_kostnad]",

  "[leieavtale]",
  "[boligselskap_tiltaksliste]",

  "[rapport_vedlikeholdslengde]",
  "[inspeksjon_befaringsdato]",
  "[inspeksjon_tilstede]",

  "[dokumentasjon_kontroll_dato]",
  "[dokumentasjon_revisjon_dato]",

  "[legend_radon_caution]",
  "[legend_ground_contamination]",

  "[vedlikehold_kostnads_tdd_tabell]",
  "[tdd_oppsummering]",
  "[vedlikehold_dokumentasjon_kostnads_tabell]",
  "[vedlikehold_dokumentasjon_kostnads_tabell_utten_mva]",

  "[bygningsdetaljer_tabell]",
];

const initEditor = function () {
  // autosave redactor
  var autosaveTimeoutID = null;
  var autosaveCallback = function (e) {
    // can be multiple nested forms, only check direct parent
    var parentForm = e.target.closest("form");
    if (!parentForm.classList.contains("autosave")) return;

    clearTimeout(autosaveTimeoutID);
    autosaveTimeoutID = setTimeout(() => {
      fire(parentForm, "submit");
    }, 1000);
  };

  document.querySelectorAll(".editor").forEach((editor) => {
    const extraOptions = {};
    const limit = editor.dataset["editorLimit"];
    if (limit) extraOptions.limiter = limit;

    $R(editor, {
      minHeight: "200px",
      imageUpload: "/upload",
      imageData: null,
      imageFigure: false,
      imageEditable: false,
      imageLink: false,
      imageCaption: false,
      imagePosition: false,
      imageResizable: false,
      buttonsAdd: ["sup", "sub"],
      plugins: ["table", "variable", "limiter"],
      variables: window.REDACTOR_EDITOR_VARIABLES,
      callbacks: {
        keyup: autosaveCallback,
      },
      ...extraOptions,
    });
  });

  $R(".editor_narrow", {
    minHeight: "100px",
    imageUpload: "/upload",
    imageData: null,
    imageFigure: false,
    imageEditable: false,
    imageLink: false,
    imageCaption: false,
    imagePosition: false,
    imageResizable: false,
    buttonsAdd: ["sup", "sub"],
    plugins: ["table", "variable", "limiter"],
    variables: REDACTOR_EDITOR_VARIABLES,
    callbacks: {
      keyup: autosaveCallback,
    },
  });

  $R(".mini_editor", {
    minHeight: "200px",
    buttons: ["bold", "italic", "link", "sup", "sub"],
    callbacks: {
      keyup: autosaveCallback,
    },
  });

  $R(".mini_editor_narrow", {
    minHeight: "100px",
    buttons: ["bold", "italic", "link", "sup", "sub"],
    callbacks: {
      keyup: autosaveCallback,
    },
  });

  $R(".table_editor", {
    minHeight: "200px",
    buttons: ["bold", "italic", "link", "sup", "sub"],
    plugins: ["table"],
    callbacks: {
      keyup: autosaveCallback,
    },
  });

  $R(".table_editor_mini", {
    minHeight: "100px",
    buttons: ["bold", "italic", "link", "sup", "sub"],
    plugins: ["table"],
    callbacks: {
      keyup: autosaveCallback,
    },
  });

  // CV editor
  document.querySelectorAll(".cv-editor").forEach((editor) => {
    const extraOptions = {};
    const limit = editor.dataset["editorLimit"];
    if (limit) extraOptions.limiter = limit;

    $R(editor, {
      minHeight: "200px",
      buttons: ["undo", "redo", "format", "bold", "italic", "lists", "link"],
      plugins: ["limiter"],
      callbacks: {
        keyup: autosaveCallback,
      },
      ...extraOptions,
    });
  });
};

export default initEditor;
