/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under .../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('.../images', true)
// const imagePath = (name) => images(name, true)

require("turbolinks").start();
require("@rails/activestorage").start();

import Rails from "@rails/ujs";
import "dom4";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.css";
import BerCookies from "../ber_cookies";
import cv from "../cv";
import editableSortable from "../editable_sortable";
import initAutoSave from "../init_auto_save";
import initEditor from "../init_editor";
import initSectionSortable from "../init_section_sortable";
import navigation from "../navigation";
import reportNavigation from "../reports/report_navigation";
import userForm from "../user_form";
import accordion from "../utils/accordion";
import clickTrigger from "../utils/click_trigger";
import collapsible from "../utils/collapsible";
import expandable from "../utils/expandable";
import headerScroll from "../utils/header_scroll";
import jumpToTop from "../utils/jump_to_top";
import notices from "../utils/notices";

Rails.start();
window.Rails = Rails;

// called by views
window.BerCookies = BerCookies;
window.initAutoSave = initAutoSave;
window.initEditor = initEditor;
window.initSectionSortable = initSectionSortable;
window.iziToast = iziToast;

document.addEventListener("DOMContentLoaded", BerCookies.pageReady);

document.addEventListener("turbolinks:load", () => {
  accordion();
  collapsible();
  clickTrigger();
  cv();
  editableSortable();
  expandable();
  headerScroll();
  initAutoSave();
  initSectionSortable();
  jumpToTop();
  navigation();
  notices();
  reportNavigation();
  userForm();

  if (
    document.querySelector(
      ".editor, .mini_editor, .editor_narrow, .table_editor, .table_editor_mini, .mini_editor_narrow, .cv-editor"
    )
  ) {
    initEditor();
  }
});

document.addEventListener("ajax:error", (e) => {
  iziToast.error({
    title: "Kunne ikke lagre dataene",
    message: "Sjekk internettforbindelsen og prøv igjen.",
    // timeout: 7500
  });
});
