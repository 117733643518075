// views call this object
window.Navigation = (function () {
  let urls = [];

  return {
    add: function (url) {
      urls.push(url);
    },
    index: function () {
      return urls.indexOf(window.location.href);
    },
    first: function () {
      return Navigation.index() === 0;
    },
    last: function () {
      return Navigation.index() === urls.length - 1;
    },
    has: function () {
      return Navigation.index() !== false;
    },
    next: function () {
      if (!Navigation.has() || Navigation.last()) {
        return;
      }

      Turbolinks.visit(urls[Navigation.index() + 1]);
    },
    prev: function () {
      if (!Navigation.has() || Navigation.first()) {
        return;
      }

      Turbolinks.visit(urls[Navigation.index() - 1]);
    },
  };
})();

const reportNavigation = () => {
  // nav next, nav prev
  document.querySelectorAll(".nav-prev").forEach(function (next) {
    next.classList.toggle("_dn", Navigation.first());

    next.addEventListener("click", function (e) {
      e.preventDefault();

      Navigation.prev();
    });
  });

  document.querySelectorAll(".nav-next").forEach(function (next) {
    next.classList.toggle("_dn", Navigation.last());

    next.addEventListener("click", function (e) {
      e.preventDefault();

      Navigation.next();
    });
  });
};

export default reportNavigation;
