const accordion = () => {
  const acordionTitles = document.querySelectorAll(".accordion-title");

  acordionTitles.forEach((title) => {
    title.addEventListener("click", (e) => {
      e.preventDefault();

      title.classList.toggle("active");
      // title.nextElementSibling.classList.toggle("show");
      title.parentElement
        .querySelector(".accordion-content")
        .classList.toggle("show");
    });
  });
};

export default accordion;
