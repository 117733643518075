import iziToast from "izitoast";

const notices = () => {
  document.querySelectorAll(".notice").forEach(notice => {
    notice.remove(); // remove from DOM
    const message = notice.innerHTML;
    if (notice.classList.contains("alert")) iziToast.warning({ message });
    else iziToast.success({ message });
  });
};

export default notices;
