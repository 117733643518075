const headerScroll = () => {
  window.addEventListener("scroll", function() {
    const header = document.querySelector(".header");
    if (header)
      header.classList.toggle(
        "black",
        window.scrollY + 60 > window.innerHeight
      );
  });
};

export default headerScroll;
