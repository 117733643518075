const expandable = () => {
  document.body.addEventListener("click", function(e) {
    const trigger = el => {
      if (el.classList && el.classList.contains("trigger-expand")) return true;
      if (!el.parentNode) return false;
      return trigger(el.parentNode);
    };
    const target = e.target;
    if (!target || !trigger(target)) return;

    const item = target.closest(".block-list-item");
    e.preventDefault();

    // button text toggle
    Array.prototype.filter.call(item.parentNode.children, function(child) {
      if (child !== item && child.classList.contains("expanded")) {
        child.classList.remove("expanded");

        let childTriger = child.querySelector(".trigger-expand");

        if (childTriger.classList.contains("text-toggle")) {
          let text = childTriger.getAttribute("data-text-toggle");

          childTriger.setAttribute("data-text-toggle", childTriger.textContent);
          childTriger.textContent = text;
        }
      }
    });

    const block_list_item = target.closest(".block-list-item");
    block_list_item.classList.toggle("expanded");

    if (block_list_item.getBoundingClientRect().bottom > window.innerHeight) {
      block_list_item.scrollIntoView(false);
    }
    if (block_list_item.getBoundingClientRect().top < 0) {
      block_list_item.scrollIntoView();
    }
  });
};

export default expandable;
