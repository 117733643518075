///COOCKIE CONTROLLED DICLAIMER PANEL
var BerCookies = {
  name: "ber-rapport",
  domain: "",
  path: "",
  use_cookie: true,
  startup: false,
  forget_duration: 90,
  dismiss_duration: 1,
  element: "#disclaimer",
  disclaimer: null,
  pageReady: function (e) {
    var cookieEnabled = navigator.cookieEnabled ? true : false;
    if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
      document.cookie = "testcookie";
      cookieEnabled =
        document.cookie.indexOf("testcookie") != -1 ? true : false;
    }
    if (cookieEnabled) {
      BerCookies.checkCookie();
    } else if (typeof Storage !== "undefined") {
      BerCookies.use_cookie = false;
      BerCookies.checkCookie();
    }
  },
  checkCookie: function () {
    //SUBSCRIPTION
    BerCookies.disclaimer = document.querySelector(BerCookies.element);
    if (document.cookie.length > 0 || window.localStorage.length > 0) {
      BerCookies.status = BerCookies.getCookie(BerCookies.name);
      // console.log("BerCookies.status", BerCookies.status);
      if (BerCookies.status === undefined) {
        BerCookies.startup = true;
      } else if (BerCookies.status == "hidden") {
        BerCookies.startup = false;
        BerCookies.removeDisclaimer();
      }
    }
    if (BerCookies.disclaimer) {
      BerCookies.disclaimer.classList.add("active");
    }
  },
  getCookie: function (name) {
    if (BerCookies.use_cookie) {
      var reg = new RegExp("(;)?" + name + "=([^;]*);?");
      var cookieValue = document.cookie.match(reg);
      if (cookieValue == null) {
        return undefined;
      } else {
        if (decodeURI(cookieValue[2]).length <= 2) return undefined;
        else return decodeURI(cookieValue[2]);
      }
    } else {
      var cookieObject = localStorage.getItem(name);
      if (cookieObject) {
        var data = JSON.parse(cookieObject);
        if (data.expires) {
          var date = new Date(data.expires);
          var now = new Date();
          if (date < now) {
            BerCookies.revokeDisclaimer();
            return undefined;
          }
        }
        if (data.cookieSettings) {
          return data.cookieSettings;
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
  },
  revokeDisclaimer: function () {
    if (BerCookies.use_cookie) {
      document.cookie =
        BerCookies.name +
        "=;expires=Thu, 01 Jan 1970 00:00:01;path=" +
        BerCookies.path +
        ";" +
        "domain=" +
        BerCookies.domain +
        ";";
    } else {
      localStorage.removeItem(BerCookies.name);
    }
  },
  removeDisclaimer: function () {
    if (BerCookies.disclaimer) {
      BerCookies.disclaimer.parentNode.removeChild(BerCookies.disclaimer);
    }
  },
  setCookie: function (name, value, life, path) {
    if (BerCookies.use_cookie) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + parseInt(life));
      var cValue =
        encodeURI(value) +
        "; expires=" +
        exdate.toUTCString() +
        ";path=" +
        path +
        ";" +
        "domain=" +
        BerCookies.domain +
        ";";
      document.cookie = name + "=" + cValue;
    } else {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + +parseInt(life));
      var data = { cookieSettings: value, expires: exdate.toString() };
      localStorage.setItem(name, JSON.stringify(data));
    }
  },
  closeDisclaimer: function () {
    var lifetime = BerCookies.disclaimer.querySelector("#disclaimer-forget")
      .checked
      ? BerCookies.forget_duration
      : BerCookies.dismiss_duration;
    // console.log(lifetime);
    BerCookies.setCookie(BerCookies.name, "hidden", lifetime, BerCookies.path);
    BerCookies.removeDisclaimer();
  },

  // autosave
};

export default BerCookies;
