const editableSortable = () => {
  const sortableToggle = document.querySelector(".sortable-toggle");
  if (!sortableToggle) return;

  const sortables = document.querySelectorAll(".sortable");

  const toggleEditable = el => {
    // handle expandable
    if (el.classList.contains("expandable")) {
      el.classList.toggle("expandable-active");
      el.querySelectorAll(".block-list-item-row").forEach(item =>
        item.classList.toggle("trigger-expand")
      );
    }
  };

  const toggleSortable = el => {
    const parent = el.closest(".section");
    const draggables = parent.querySelectorAll(".block-list-item-row");

    el.classList.toggle("sortable-active");
    draggables.forEach(el => {
      el.setAttribute(
        "draggable",
        el.getAttribute("draggable") === "false" ? true : false
      );
    });
  };

  sortables.forEach(el => {
    const section = el.closest(".section");
    const editableToggle = section.querySelector(".editable-toggle");
    const sortableToggle = section.querySelector(".sortable-toggle");
    const toggles = [editableToggle, sortableToggle];

    el.addEventListener("sortable-initialized", () => {
      if (!sortableToggle.classList.contains("active")) toggleSortable(el);
      else toggleEditable(el);
    });

    toggles.forEach(toggle => {
      toggle.addEventListener("click", _ev => {
        if (toggle.classList.contains("active")) return;
        toggles.forEach(tgl => tgl.classList.remove("active"));
        toggle.classList.add("active");
        toggleEditable(el);
        toggleSortable(el);
      });
    });
  });
};

export default editableSortable;
