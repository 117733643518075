import sortable from "html5sortable/dist/html5sortable.es";

const initSectionSortable = function() {
  const sortableWrap = document.querySelector(".block-list.sortable");
  if (!sortableWrap) return;

  sortable(".block-list.sortable", {
    items: ":not(.disabled)",
    handle: ".block-list-item-row"
  });
  sortable(".block-list.sortable")[0].addEventListener("sortupdate", function(
    e
  ) {
    // console.log(e, e.detail, e.detail.item);
    if (!e.currentTarget.classList.contains("async")) {
      Rails.fire(e.detail.item.closest("form"), "submit");
    } else {
      // console.log("async");
      var holder = e.currentTarget;
      var form = document.querySelector(
        "#" + e.currentTarget.getAttribute("data-trigger")
      );
      var variable = e.currentTarget.getAttribute("data-variable");
      var dataInputs = holder.querySelectorAll('[name="' + variable + '[]"');
      var temp = holder.querySelector(".temp-fields");
      if (!temp) {
        temp = document.createElement("div");
        temp.style.display = "none";
        form.appendChild(temp);
      }
      dataInputs.forEach(function(item) {
        var el = document.createElement("input");
        el.type = item.type;
        el.name = item.name;
        el.value = item.value;
        temp.appendChild(el);
      });
      // console.log(temp);
      // console.log(form, variable, dataInputs);
      Rails.fire(form, "submit");
    }
  });

  document
    .querySelectorAll(".sortable")
    .forEach(el => el.classList.add("sortable-active"));
  sortableWrap.dispatchEvent(new Event("sortable-initialized"));
};

export default initSectionSortable;
